import { get, post } from '@common-src/service/request';
import { dateFormat } from '@common-src/filter';
import CommonService from '@common-src/service/common';
import { PropertyEntityType } from '@common-src/model/enum';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/computerRoomSystem`;
class ComputerRoomIndexService {
    /**
     * 获取车位使用实时统计
     * @returns
     */
    async getCapacityCount(params?: any) {
        const url = `${URL_PATH}/rts/capacity/count`;
        const res = await post(url);
        return res;
    }

    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityTotalCount() {
        const resFacilityCount = await get(`${URL_PATH}/facility/rts/count`);
        return resFacilityCount;
    }

    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityRunningCount() {
        const resFacilityStatusCount = await get(`${URL_PATH}/facility/rts/countByState`);
        return resFacilityStatusCount;
    }

    /**
     * 查询告警总数
     * @returns
     */
    async getAlarmCount() {
        const res = await get(`${URL_PATH}/alarm/rts/count`);
        return res;
    }

    /**
     * 正在告警数量
     * @returns
     */
    async getAlarmActiveCount() {
        const url = `${URL_PATH}/alarm/hts/ACTIVE/count`;
        const res = await post(url);
        return res;
    }

    /**
     * 告警状态数量
     * @returns
     */
    async getAlarmStatusNumber() {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 今天告警状态统计
     * @returns
     */
    async getAlarmStatusCount(params?: any) {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url, params);
        return {
            columns: ['name', '数量'],
            rows: [
                { name: '已处理', '数量': _.get(res, 'solvedCount') || 0 },
                { name: '未处理', '数量': _.get(res, 'unsolvedCount') || 0 }
            ]
        };
    }

    /**
     * 近7天告警趋势
     * @returns
     */
    async getLast7DayAlarmCount() {
        const url = `${URL_PATH}/alarm/rts/countTrends`;
        const res = await get(url);
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }

    /**
     * 告警级别统计
     * @returns
     */
    async getAlarmGradeCount() {
        const eventGradeList = await CommonService.getPropertyOptions(PropertyEntityType.EVENT_GRADE);
        const url = `${URL_PATH}/alarm/rts/GRADE/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: _.map(_.keys(res), item => {
                return {
                    name: _.get(_.find(eventGradeList, eventGrade => eventGrade.code === item), 'name') || item,
                    value: res[item]
                };
            })
        };
    }
}

export default new ComputerRoomIndexService();
