import { ParkingGateType, ChartType, SubSystemNumberEnum, SubSystemChartEnum, SubSystemType } from '@common-src/model/enum';
import ParkingIndexService from '@/pages/sub-system/service/parking-index';
import { ParkingTrafficFlowQueryModel } from '@/pages/sub-system/model/parking-traffic-flow-model';
import { ParkingTrafficFlowRankingQueryModel } from '@/pages/sub-system/model/parking-traffic-flow-ranking-model';
import FireIndexService from '@/pages/sub-system/service/fire-index';
import { FireAlarmTrendQueryModel } from '@/pages/sub-system/model/fire-alarm-trend-model';
import CameraIndexService from '@/pages/sub-system/service/camera-index';
import EnviromentIndexService from '@/pages/sub-system/service/enviroment-index';
import { EnvAirQualityQueryModel } from '@/pages/sub-system/model/env-air-quality-model';
import { EnvTemperatureHumidityQueryModel } from '@/pages/sub-system/model/env-temperature-humidity-model';
import EnergyIndexService from '@/pages/sub-system/service/energy-index';
import { EnergyElectricityRtsQueryModel } from '@/pages/sub-system/model/energy-electricity-rts-model';
import BAIndexService from '@/pages/sub-system/service/ba-index';
import { BAFacilityRunTimeQueryModel } from '@/pages/sub-system/model/ba-facility-run-time-model';
import { BAFacilityFaultQueryModel } from '@/pages/sub-system/model/ba-facility-fault-model';
import CustomerFlowIndexService from '@/pages/sub-system/service/customer-flow';
import { CustomerFlowLocationQueryModel } from '@/pages/sub-system/model/customer-flow-location';
import { CustomerFlowTrendQueryModel } from '@/pages/sub-system/model/customer-flow-trend';
import ElevatorIndexService from '@/pages/sub-system/service/elevator-index';
import { ElevatorAlarmTrendQueryModel } from '@/pages/sub-system/model/elevator-alarm-trend';
import LightIndexService from '@/pages/sub-system/service/light-index';
import { LightAlarmTrendQueryModel } from '@/pages/sub-system/model/light-alarm-trend';
import AccessControlIndexService from '@/pages/sub-system/service/access-control-index';
import { AccessControlAlarmTrendQueryModel } from '@/pages/sub-system/model/access-control-alarm-trend';
import { AccessControlRtsTypeQueryModel } from '@/pages/sub-system/model/access-control-rts-type';
import ComputerRoomIndexService from '../service/computer-room-index';
import { ComputerRoomCapacityService, ComputerRoomCapacityQueryModel } from '../model/computer-room-capacity';
import { ParkingFacilityQueryModel } from './parking-facility-entity';
import ParkingFacilityService from '../service/parking-facility';

export const HeaderCardList = [
    {
        id: SubSystemNumberEnum.ParkingSpaceTotal,
        dataIndex: 'totalCount',
        title: '车位总数',
        count: null,
        unit: '个',
        loading: true,
        icon: '/img/parking.png',
        service: ParkingIndexService.getParkingSpaceUsage,
        serviceParams: null,
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemNumberEnum.ParkingFacilityTotal,
        dataIndex: 'totalCount',
        title: '设备总数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device.png',
        service: ParkingIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemNumberEnum.ParkingIncomeTotal,
        dataIndex: 'totalCount',
        title: '今日累计驶入车次',
        count: null,
        unit: '次',
        loading: true,
        icon: '/img/in-total.png',
        service: ParkingIndexService.getTrafficFlowCount,
        serviceParams: null,
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemNumberEnum.ParkingFacilityFault,
        dataIndex: 'faultCount',
        title: '异常设备',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/fault.png',
        service: ParkingIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemNumberEnum.ParkingAlarmUnsolved,
        dataIndex: 'unsolvedCount',
        title: '未处理告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-unhandle.png',
        service: ParkingIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemNumberEnum.FireAlarmActive,
        dataIndex: 'activeCount',
        title: '正在告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm.png',
        service: FireIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemNumberEnum.FireAlarmUnsolved,
        dataIndex: 'unsolvedCount',
        title: '今天未处理告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-unhandle.png',
        service: FireIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemNumberEnum.FireAlarmTotal,
        dataIndex: 'totalCount',
        title: '今天告警总数',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-total.png',
        service: FireIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemNumberEnum.FireFacilityFault,
        dataIndex: 'faultCount',
        title: '异常设备',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/fault.png',
        service: FireIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemNumberEnum.FireFacilityTotal,
        dataIndex: 'totalCount',
        title: '设备总数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device.png',
        service: FireIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemNumberEnum.CameraFacilityTotal,
        dataIndex: 'totalCount',
        title: '设备总数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device.png',
        service: CameraIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.CAMERA
    },
    {
        id: SubSystemNumberEnum.CameraFacilityOnline,
        dataIndex: 'onlineCount',
        title: '在线设备数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device-online.png',
        service: CameraIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.CAMERA
    },
    {
        id: SubSystemNumberEnum.CameraFacilityOffline,
        dataIndex: 'offlineCount',
        title: '离线设备数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device-offline.png',
        service: CameraIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.CAMERA
    },
    {
        id: SubSystemNumberEnum.CameraAlarmTotal,
        dataIndex: 'totalCount',
        title: '今天告警总数',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-total.png',
        service: CameraIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.CAMERA
    },
    {
        id: SubSystemNumberEnum.CameraAlarmUnsolved,
        dataIndex: 'unsolvedCount',
        title: '今天未处理告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-unhandle.png',
        service: CameraIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.CAMERA
    },
    {

        id: SubSystemNumberEnum.EnvFacilityTotal,
        dataIndex: 'totalCount',
        title: '设备总数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device.png',
        service: EnviromentIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemNumberEnum.EnvFacilityOnline,
        dataIndex: 'onlineCount',
        title: '在线设备数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device-online.png',
        service: EnviromentIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemNumberEnum.EnvAlarmTotal,
        dataIndex: 'totalCount',
        title: '今天告警总数',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-total.png',
        service: EnviromentIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemNumberEnum.EnvAlarmActive,
        dataIndex: 'activeCount',
        title: '正在告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm.png',
        service: EnviromentIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemNumberEnum.EnvAlarmUnsolved,
        dataIndex: 'unsolvedCount',
        title: '今天未处理告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-unhandle.png',
        service: EnviromentIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemNumberEnum.ElecToday,
        dataIndex: 'dayCount',
        title: '今天用电',
        count: null,
        unit: 'kWh',
        loading: true,
        icon: '/img/day-bar.png',
        service: EnergyIndexService.getElectricityCount,
        serviceParams: null,
        systemId: SubSystemType.ENERGY
    },
    {
        id: SubSystemNumberEnum.ElecMonth,
        dataIndex: 'monthCount',
        title: '本月用电',
        count: null,
        unit: 'kWh',
        loading: true,
        icon: '/img/month-bar.png',
        service: EnergyIndexService.getElectricityCount,
        serviceParams: null,
        systemId: SubSystemType.ENERGY
    },
    {
        id: SubSystemNumberEnum.ElecYear,
        dataIndex: 'yearCount',
        title: '今年用电',
        count: null,
        unit: 'kWh',
        loading: true,
        icon: '/img/year-bar.png',
        service: EnergyIndexService.getElectricityCount,
        serviceParams: null,
        systemId: SubSystemType.ENERGY
    },
    {
        id: SubSystemNumberEnum.ElecAlarmActive,
        dataIndex: 'activeCount',
        title: '正在告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm.png',
        service: EnergyIndexService.getAlarmActiveCount,
        serviceParams: null,
        systemId: SubSystemType.ENERGY
    },
    {
        id: SubSystemNumberEnum.ElecFacilityFault,
        dataIndex: 'faultCount',
        title: '异常设备',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/fault.png',
        service: EnergyIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.ENERGY
    },
    {
        id: SubSystemNumberEnum.BAFacilityTotal,
        dataIndex: 'totalCount',
        title: '总设备数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device.png',
        service: BAIndexService.getFacilityTotalCount,
        serviceParams: null,
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemNumberEnum.BAFcilityRunning,
        dataIndex: 'runingCount',
        title: '运行设备数',
        count: null,
        unit: '个',
        loading: true,
        icon: '/img/device-online.png',
        service: BAIndexService.getFacilityRunningCount,
        serviceParams: null,
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemNumberEnum.BAFacilityFault,
        dataIndex: 'faultCount',
        title: '故障设备数',
        count: null,
        unit: '个',
        loading: true,
        icon: '/img/fault.png',
        service: BAIndexService.getFacilityTotalCount,
        serviceParams: null,
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemNumberEnum.BAAlarmActive,
        dataIndex: 'activeCount',
        title: '正在告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm.png',
        service: BAIndexService.getAlarmActiveCount,
        serviceParams: null,
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemNumberEnum.BAAlarmUnsolved,
        dataIndex: 'unsolvedCount',
        title: '今天未处理告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-unhandle.png',
        service: BAIndexService.getAlarmStatusNumber,
        serviceParams: null,
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemNumberEnum.CustRetainedCount,
        dataIndex: 'retainedFlow',
        title: '留存客流',
        count: null,
        unit: '人',
        loading: true,
        icon: '/img/customer-retained.png',
        service: CustomerFlowIndexService.getRetainedFlowCount,
        serviceParams: null,
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemNumberEnum.CustHourInCount,
        dataIndex: 'inCount',
        title: '一小时累计流入',
        count: null,
        unit: '人次',
        loading: true,
        icon: '/img/customer-in.png',
        service: CustomerFlowIndexService.gethourInOutFlowCount,
        serviceParams: null,
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemNumberEnum.CustHourOutCount,
        dataIndex: 'outCount',
        title: '一小时累计流出',
        count: null,
        unit: '人次',
        loading: true,
        icon: '/img/customer-out.png',
        service: CustomerFlowIndexService.gethourInOutFlowCount,
        serviceParams: null,
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemNumberEnum.CustTodayInCount,
        dataIndex: 'inCount',
        title: '今天累计客流',
        count: null,
        unit: '人次',
        loading: true,
        icon: '/img/customer-day.png',
        service: CustomerFlowIndexService.getDayOfInFlowCount,
        serviceParams: null,
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemNumberEnum.CustMonthInCount,
        dataIndex: 'inCount',
        title: '本月累计客流',
        count: null,
        unit: '人次',
        loading: true,
        icon: '/img/customer-month.png',
        service: CustomerFlowIndexService.getMonthOfInFlowCount,
        serviceParams: null,
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemNumberEnum.ElevatorFacilityTotal,
        dataIndex: 'totalCount',
        title: '设备总数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device.png',
        service: ElevatorIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.ELEVATOR
    },
    {
        id: SubSystemNumberEnum.ElevatorFacilityNormal,
        dataIndex: 'normalCount',
        title: '正常总数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device-online.png',
        service: ElevatorIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.ELEVATOR
    },
    {
        id: SubSystemNumberEnum.ElevatorFacilityFault,
        dataIndex: 'faultCount',
        title: '异常设备',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/fault.png',
        service: ElevatorIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.ELEVATOR
    },
    {
        id: SubSystemNumberEnum.ElevatorAlarmActive,
        dataIndex: 'activeCount',
        title: '正在告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm.png',
        service: ElevatorIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.ELEVATOR
    },
    {
        id: SubSystemNumberEnum.ElevatorAlarmUnsoloved,
        dataIndex: 'unsolvedCount',
        title: '未处理告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-unhandle.png',
        service: ElevatorIndexService.getAlarmStatusCount,
        serviceParams: null,
        systemId: SubSystemType.ELEVATOR
    },
    {
        id: SubSystemNumberEnum.LightTodayCount,
        dataIndex: 'dayCount',
        title: '今天用电量',
        count: null,
        unit: 'kWh',
        loading: true,
        icon: '/img/day-bar.png',
        service: LightIndexService.getElectricityCount,
        serviceParams: null,
        systemId: SubSystemType.LIGHT
    },
    {
        id: SubSystemNumberEnum.LightMonthCount,
        dataIndex: 'monthCount',
        title: '本月用电量',
        count: null,
        unit: 'kWh',
        loading: true,
        icon: '/img/month-bar.png',
        service: LightIndexService.getElectricityCount,
        serviceParams: null,
        systemId: SubSystemType.LIGHT
    },
    {
        id: SubSystemNumberEnum.LightFacilityTotal,
        dataIndex: 'totalCount',
        title: '设备总数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device-online.png',
        service: LightIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.LIGHT
    },
    {
        id: SubSystemNumberEnum.LightFacilityFault,
        dataIndex: 'faultCount',
        title: '故障设备',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/fault.png',
        service: LightIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.LIGHT
    },
    {
        id: SubSystemNumberEnum.LightAlarmActive,
        dataIndex: 'activeCount',
        title: '正在告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm.png',
        service: LightIndexService.getAlarmActiveCount,
        serviceParams: null,
        systemId: SubSystemType.LIGHT
    },
    {
        id: SubSystemNumberEnum.ACCount,
        dataIndex: 'totalCount',
        title: '园内人数',
        count: null,
        unit: '人',
        loading: true,
        icon: '/img/device.png',
        service: AccessControlIndexService.getRtsCount,
        serviceParams: null,
        systemId: SubSystemType.ACCESS_CONTROL
    },
    {
        id: SubSystemNumberEnum.ACFacilityTotal,
        dataIndex: 'totalCount',
        title: '设备总数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device-online.png',
        service: AccessControlIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.ACCESS_CONTROL
    },
    {
        id: SubSystemNumberEnum.ACFacilityFault,
        dataIndex: 'faultCount',
        title: '异常设备',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/fault.png',
        service: AccessControlIndexService.getFacilityCount,
        serviceParams: null,
        systemId: SubSystemType.ACCESS_CONTROL
    },
    {
        id: SubSystemNumberEnum.ACAlarmToday,
        dataIndex: 'totalCount',
        title: '今天告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-unhandle.png',
        service: AccessControlIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.ACCESS_CONTROL
    },
    {
        id: SubSystemNumberEnum.ACAlarmActive,
        dataIndex: 'activeCount',
        title: '正在告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm.png',
        service: AccessControlIndexService.getAlarmActive,
        serviceParams: null,
        systemId: SubSystemType.ACCESS_CONTROL
    },
    {
        dataIndex: 'totalCount',
        title: '总设备数',
        count: null,
        unit: '台',
        loading: true,
        icon: '/img/device.png',
        service: ComputerRoomIndexService.getFacilityTotalCount,
        serviceParams: null,
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        dataIndex: 'onlineCount',
        title: '在线设备数',
        count: null,
        unit: '个',
        loading: true,
        icon: '/img/device-online.png',
        service: ComputerRoomIndexService.getFacilityTotalCount,
        serviceParams: null,
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        dataIndex: 'totalCount',
        title: '今天告警总数',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-total.png',
        service: ComputerRoomIndexService.getAlarmCount,
        serviceParams: null,
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        dataIndex: 'activeCount',
        title: '正在告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm.png',
        service: ComputerRoomIndexService.getAlarmActiveCount,
        serviceParams: null,
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        dataIndex: 'unsolvedCount',
        title: '今天未处理告警',
        count: null,
        unit: '条',
        loading: true,
        icon: '/img/alarm-unhandle.png',
        service: ComputerRoomIndexService.getAlarmStatusNumber,
        serviceParams: null,
        systemId: SubSystemType.COMPUTER_ROOM
    }
];

export const ChartConfigList = [
    {
        id: SubSystemChartEnum.ParkingStatus,
        title: '车位使用实时统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/rate',
        chartType: ChartType.GAUGE,
        chartData: null,
        percentId: 'rate',
        percentDesc: '停车率',
        legendTitle: '全部',
        legendItems: [
            {
                title: '车位总数',
                dataIndex: 'totalCount'
            },
            {
                title: '剩余车位',
                dataIndex: 'freeCount'
            }
        ],
        service: ParkingIndexService.getParkingSpaceUsage,
        serviceParams: '',
        showChartTable: true,
        chartTableDataSource: 'children',
        chartTableColumns: [
            {
                dataIndex: 'locationName',
                title: '区域'
            },
            {
                dataIndex: 'totalCount',
                title: '车位总数'
            },
            {
                dataIndex: 'freeCount',
                title: '剩余车位'
            },
            {
                dataIndex: 'rate',
                title: '停车率',
                scopedSlots: { customRender: 'rate' }
            }
        ],
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemChartEnum.ParkingTodayTrafficFlow,
        title: '今天车流量统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/traffic-flow',
        chartType: ChartType.LINE,
        chartData: null,
        setting: {
            type: 'line',
            area: true,
            // yAxisType: ['KMB'],
            yAxisName: ['车次'],
            labelMap: { inCount: '驶入', outCount: '驶出' }
        },
        service: ParkingIndexService.getTrafficFlow,
        serviceParams: new ParkingTrafficFlowQueryModel(),
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemChartEnum.ParkingEntranceType,
        title: '出入口统计',
        loading: true,
        redirectPath: '/dashboard/parking-entrance-exit-list',
        chartType: ChartType.PIE,
        chartData: null,
        service: ParkingIndexService.getParkingGate,
        serviceParams: '',
        showChartTable: true,
        chartTableDataSource: 'children',
        chartTableColumns: [
            {
                dataIndex: 'locationName',
                title: '区域'
            },
            {
                dataIndex: 'entranceCount',
                title: '入口'
            },
            {
                dataIndex: 'exitCount',
                title: '出口'
            }
        ],
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemChartEnum.ParkingAlarmStatus,
        title: '今天告警状态统计',
        loading: true,
        redirectPath: '/dashboard/parking-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: ParkingIndexService.getRuleEventCount,
        serviceParams: '',
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemChartEnum.ParkingGateRank,
        title: '今天出入口流量排名',
        loading: true,
        redirectPath: '/dashboard/parking-chart/traffic-flow-ranking',
        chartType: ChartType.BAR,
        chartData: null,
        service: ParkingIndexService.getParkingGateRank,
        serviceParams: new ParkingTrafficFlowRankingQueryModel(5),
        group: [
            {
                text: '入口',
                value: ParkingGateType.ENTRANCE
            },
            {
                text: '出口',
                value: ParkingGateType.EXIT
            }
        ],
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemChartEnum.ParkingFacilityStatus,
        title: '设备状态统计',
        loading: true,
        redirectPath: '/dashboard/parking-facility-list',
        chartType: ChartType.PIE,
        chartData: null,
        service: ParkingIndexService.getFacilityCount,
        serviceParams: '',
        systemId: SubSystemType.PARKING
    },
    {
        id: SubSystemChartEnum.FireAlarmDistribution,
        title: '今天告警分布统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/fire-location-alarm',
        chartType: ChartType.HISTOGRAM,
        chartData: null,
        service: FireIndexService.getLocationAlarmCount,
        serviceParams: '',
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemChartEnum.FireAlarmStatus,
        title: '今天告警状态统计',
        loading: true,
        redirectPath: '/dashboard/fire-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: FireIndexService.getAlarmStatus,
        serviceParams: '',
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemChartEnum.FireAlarmTrend,
        title: '近7天告警趋势',
        loading: true,
        redirectPath: '/dashboard/parking-chart/fire-alarm-trend',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: FireIndexService.getLast7DayAlarmCount,
        serviceParams: new FireAlarmTrendQueryModel().toService(),
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemChartEnum.FireAlarmLevel,
        title: '今天告警级别统计',
        loading: true,
        redirectPath: '/dashboard/fire-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: FireIndexService.getAlarmGradeCount,
        serviceParams: '',
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemChartEnum.FireAlarmRank,
        title: '近7天告警排名TOP5',
        loading: true,
        redirectPath: '/dashboard/parking-chart/fire-alarm-rank',
        chartType: ChartType.BAR,
        chartData: null,
        service: ParkingIndexService.getParkingGateRank,
        serviceParams: new ParkingTrafficFlowRankingQueryModel(5),
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemChartEnum.FireFacilityStatus,
        title: '设备状态统计',
        loading: true,
        redirectPath: '/dashboard/fire-facility-list',
        chartType: ChartType.PIE,
        chartData: null,
        service: FireIndexService.getFacilityStatus,
        serviceParams: '',
        systemId: SubSystemType.FIRE
    },
    {
        id: SubSystemChartEnum.CameraFacilityStatus,
        title: '设备状态统计',
        loading: true,
        redirectPath: '/dashboard/camera-facility-list',
        chartType: ChartType.HISTOGRAM,
        chartData: null,
        service: CameraIndexService.getFacilityStatusCount,
        serviceParams: '',
        systemId: SubSystemType.CAMERA
    },
    {
        id: SubSystemChartEnum.CameraAlarmDistribution,
        title: '设备分布统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/camera-facility-location',
        chartType: ChartType.PIE,
        chartData: null,
        service: CameraIndexService.getFacilityLocationCount,
        serviceParams: '',
        systemId: SubSystemType.CAMERA
    },
    {
        id: SubSystemChartEnum.CameraAlarmTrend,
        title: '近7天告警趋势',
        loading: true,
        redirectPath: '/dashboard/parking-chart/camera-alarm-trend',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: CameraIndexService.getLast7DayAlarmCount,
        serviceParams: '',
        systemId: SubSystemType.CAMERA
    },
    {
        id: SubSystemChartEnum.CameraAlarmStatus,
        title: '今天告警状态统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/camera-alarm-status',
        chartType: ChartType.HISTOGRAM,
        chartData: null,
        service: CameraIndexService.getAlarmStatusCountByType,
        serviceParams: '',
        systemId: SubSystemType.CAMERA
    },
    {
        id: SubSystemChartEnum.EnvAirQuality,
        title: '空气质量指标',
        loading: true,
        redirectPath: '/dashboard/parking-chart/enviroment-air-quality',
        chartType: ChartType.HISTOGRAM,
        chartData: null,
        service: EnviromentIndexService.getAirQualityCountData,
        serviceParams: new EnvAirQualityQueryModel(),
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemChartEnum.EnvTemp,
        title: '温湿度统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/enviroment-temperature-humidity',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: EnviromentIndexService.getTemperatureHumidityCount,
        serviceParams: new EnvTemperatureHumidityQueryModel(),
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemChartEnum.EnvAlarmDistribution,
        title: '今天告警分布统计',
        loading: true,
        redirectPath: '/dashboard/enviroment-event',
        chartType: ChartType.HISTOGRAM,
        chartData: null,
        service: EnviromentIndexService.getAlarmLocationCount,
        serviceParams: '',
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemChartEnum.ElecAlarmStatus,
        title: '今天告警状态统计',
        loading: true,
        redirectPath: '/dashboard/enviroment-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: EnviromentIndexService.getAlarmStatusCount,
        serviceParams: '',
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemChartEnum.EnvAlarmTrend,
        title: '近7天告警趋势',
        loading: true,
        redirectPath: '/dashboard/enviroment-event',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: EnviromentIndexService.getLast7DayAlarmCount,
        serviceParams: '',
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemChartEnum.EnvAlarmLevel,
        title: '今天告警级别统计',
        loading: true,
        redirectPath: '/dashboard/enviroment-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: EnviromentIndexService.getAlarmGradeCount,
        serviceParams: '',
        systemId: SubSystemType.ENVIROMENT
    },
    {
        id: SubSystemChartEnum.ElecCount,
        title: '实时用电监控',
        loading: true,
        redirectPath: '/dashboard/parking-chart/energy-electricity-rts',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: EnergyIndexService.getElectricityRtsData,
        serviceParams: new EnergyElectricityRtsQueryModel().toService(),
        systemId: SubSystemType.ENERGY
    },
    {
        id: SubSystemChartEnum.ElecType,
        title: '分项用电统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/energy-electricity-items',
        chartType: ChartType.PIE,
        chartData: null,
        service: EnergyIndexService.getElectricityItemsData,
        serviceParams: { intervalType: 'DAY' },
        group: [
            {
                text: '日',
                value: 'DAY'
            },
            {
                text: '月',
                value: 'MONTH'
            },
            {
                text: '年',
                value: 'YEAR'
            }
        ],
        systemId: SubSystemType.ENERGY
    },
    {
        id: SubSystemChartEnum.ElecMonth,
        title: '月度用电统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/energy-electricity-month',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: EnergyIndexService.getElectricityMonthData,
        serviceParams: '',
        systemId: SubSystemType.ENERGY
    },
    {
        id: SubSystemChartEnum.ElecAlarmStatus,
        title: '今天告警状态统计',
        loading: true,
        redirectPath: '/dashboard/enviroment-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: EnergyIndexService.getAlarmStatusCount,
        serviceParams: '',
        systemId: SubSystemType.ENERGY
    },
    {
        id: SubSystemChartEnum.BARunningSpan,
        title: '运行时长统计(月)',
        loading: true,
        redirectPath: '/dashboard/parking-chart/ba-facility-run-time',
        chartType: ChartType.HISTOGRAM,
        chartData: null,
        service: BAIndexService.getFacilityRunTime,
        serviceParams: new BAFacilityRunTimeQueryModel().toService(),
        systemId: SubSystemType.BA
    },
    // {
    //     id: SubSystemChartEnum.BAWaterPressure,
    //     title: '水压实时监测',
    //     loading: true,
    //     redirectPath: '/dashboard/parking-chart/ba-water-press',
    //     chartType: ChartType.LINE,
    //     chartData: null,
    //     hideLegend: true,
    //     service: ',
    //     serviceParams: '',
    //     systemId: SubSystemType.BA
    // }
    {
        id: SubSystemChartEnum.BAFacilityStatus,
        title: '设备状态统计',
        loading: true,
        redirectPath: '/dashboard/ba-facility-list',
        chartType: ChartType.PIE,
        chartData: null,
        service: BAIndexService.getFacilityStatus,
        serviceParams: '',
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemChartEnum.BAFacilityFaultType,
        title: '故障设备分类统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/ba-facility-fault',
        chartType: ChartType.HISTOGRAM,
        chartData: null,
        service: BAIndexService.getFacilityFaultCount,
        serviceParams: new BAFacilityFaultQueryModel().toService(),
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemChartEnum.BAAlarmRank,
        title: '近7天告警排名TOP5',
        loading: true,
        redirectPath: '/dashboard/ba-event',
        chartType: ChartType.BAR,
        chartData: null,
        service: BAIndexService.getLast7DayTop5AlarmCount,
        serviceParams: { limit: 5 },
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemChartEnum.BAAlarmStatus,
        title: '今天告警状态统计',
        loading: true,
        redirectPath: '/dashboard/ba-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: BAIndexService.getAlarmStatusCount,
        serviceParams: '',
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemChartEnum.BAAlarmTrend,
        title: '近7天告警趋势',
        loading: true,
        redirectPath: '/dashboard/ba-event',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: BAIndexService.getLast7DayAlarmCount,
        serviceParams: '',
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemChartEnum.BAAlarmLevel,
        title: '今天告警级别统计',
        loading: true,
        redirectPath: '/dashboard/ba-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: BAIndexService.getAlarmGradeCount,
        serviceParams: '',
        systemId: SubSystemType.BA
    },
    {
        id: SubSystemChartEnum.CustRetainedDistribution,
        title: '今天留存客流分布',
        loading: true,
        redirectPath: '/dashboard/parking-chart/customer-flow-retained-location',
        chartType: ChartType.PIE,
        chartData: null,
        service: CustomerFlowIndexService.getLocationCount,
        serviceParams: new CustomerFlowLocationQueryModel('RETAINEDFLOW').toService(),
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemChartEnum.CustInCountDistribution,
        title: '今天累计客流分布',
        loading: true,
        redirectPath: '/dashboard/parking-chart/customer-flow-in-location',
        chartType: ChartType.PIE,
        chartData: null,
        service: CustomerFlowIndexService.getLocationCount,
        serviceParams: new CustomerFlowLocationQueryModel('INFLOW').toService(),
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemChartEnum.CustFlowTrend,
        title: '今天客流趋势',
        loading: true,
        redirectPath: '/dashboard/parking-chart/customer-flow-trend',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: CustomerFlowIndexService.getTrendCount,
        serviceParams: new CustomerFlowTrendQueryModel(['INFLOW']).toService(),
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemChartEnum.CustWeeklyFlowTrend,
        title: '一周客流趋势',
        loading: true,
        redirectPath: '/dashboard/parking-chart/customer-flow-week-trend',
        chartType: ChartType.HISTOGRAM,
        chartData: null,
        service: CustomerFlowIndexService.getTrendCount,
        serviceParams: new CustomerFlowTrendQueryModel(['INFLOW'], true).toService(),
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemChartEnum.CustAlarmRank,
        title: '近7天告警排名TOP5',
        loading: true,
        redirectPath: '/dashboard/customer-flow-event',
        chartType: ChartType.BAR,
        chartData: null,
        service: CustomerFlowIndexService.getLast7DayTop5AlarmCount,
        serviceParams: { limit: 5 },
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemChartEnum.CustAlarmStatus,
        title: '今天告警状态统计',
        loading: true,
        redirectPath: '/dashboard/customer-flow-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: CustomerFlowIndexService.getAlarmStatusCount,
        serviceParams: '',
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemChartEnum.CustAlarmTrend,
        title: '近7天告警趋势',
        loading: true,
        redirectPath: '/dashboard/customer-flow-event',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: CustomerFlowIndexService.getLast7DayAlarmCount,
        serviceParams: '',
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemChartEnum.CustAlarmLevel,
        title: '今天告警级别统计',
        loading: true,
        redirectPath: '/dashboard/customer-flow-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: CustomerFlowIndexService.getAlarmGradeCount,
        serviceParams: '',
        systemId: SubSystemType.CUSTOMER_FLOW
    },
    {
        id: SubSystemChartEnum.ElevatorFacilityStatus,
        title: '设备状态统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/elevator-facility-status',
        chartType: ChartType.PIE,
        chartData: null,
        service: ElevatorIndexService.getFacilityStatusCount,
        serviceParams: '',
        systemId: SubSystemType.ELEVATOR
    },
    {
        id: SubSystemChartEnum.ElevatorAlarmStatus,
        title: '告警状态统计',
        loading: true,
        redirectPath: '/dashboard/elevator-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: ElevatorIndexService.getAlarmStatusCount,
        serviceParams: '',
        systemId: SubSystemType.ELEVATOR
    },
    {
        id: SubSystemChartEnum.ElevatorFacilityDistribution,
        title: '设备空间分布统计',
        loading: true,
        redirectPath: '/dashboard/elevator-facility-list',
        chartType: ChartType.HISTOGRAM,
        chartData: null,
        service: ElevatorIndexService.getFacilityLocationCount,
        serviceParams: '',
        systemId: SubSystemType.ELEVATOR
    },
    {
        id: SubSystemChartEnum.ElevatorAlarmRank,
        title: '近7天告警趋势',
        loading: true,
        redirectPath: '/dashboard/parking-chart/elevator-alarm-trend',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: ElevatorIndexService.getLast7DayAlarmCount,
        serviceParams: new ElevatorAlarmTrendQueryModel(),
        systemId: SubSystemType.ELEVATOR
    },
    // {
    //     id: SubSystemChartEnum.LightRate,
    //     title: '亮灯率统计',
    //     loading: true,
    //     redirectPath: '/dashboard/parking-chart/light-rate',
    //     chartType: ChartType.GAUGE,
    //     chartData: null,
    //     percentId: 'rate',
    //     percentDesc: '亮灯率',
    //     legendTitle: '全部',
    //     legendItems: [
    //         {
    //             title: '总数',
    //             dataIndex: 'totalCount'
    //         },
    //         {
    //             title: '亮灯数',
    //             dataIndex: 'freeCount'
    //         }
    //     ],
    //     service: '',
    //     serviceParams: '',
    //     showChartTable: true,
    //     chartTableDataSource: 'children',
    //     chartTableColumns: [
    //         {
    //             dataIndex: 'locationName',
    //             title: '区域'
    //         },
    //         {
    //             dataIndex: 'totalCount',
    //             title: '总数'
    //         },
    //         {
    //             dataIndex: 'freeCount',
    //             title: '亮灯数'
    //         },
    //         {
    //             dataIndex: 'rate',
    //             title: '亮灯率',
    //             scopedSlots: { customRender: 'rate' }
    //         }
    //     ],
    //     systemId: SubSystemType.LIGHT
    // },
    {
        id: SubSystemChartEnum.LightFacilityStatus,
        title: '设备状态统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/light-facility-status',
        chartType: ChartType.PIE,
        chartData: null,
        service: LightIndexService.getFacilityStatusCount,
        serviceParams: '',
        systemId: SubSystemType.LIGHT
    },
    {
        id: SubSystemChartEnum.LightAlarmTrend,
        title: '近7天告警趋势',
        loading: true,
        redirectPath: '/dashboard/parking-chart/light-alarm-trend',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: LightIndexService.getLast7DayAlarmCount,
        serviceParams: new LightAlarmTrendQueryModel(),
        systemId: SubSystemType.LIGHT
    },
    {
        id: SubSystemChartEnum.LightMonthStatus,
        title: '月度用电统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/light-electricity-month',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: LightIndexService.getElectricityMonthData,
        serviceParams: '',
        systemId: SubSystemType.LIGHT
    },
    {
        id: SubSystemChartEnum.ACTrend,
        title: '园内人员统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/access-control-rts',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: AccessControlIndexService.getRtsTotalCount,
        serviceParams: '',
        systemId: SubSystemType.ACCESS_CONTROL
    },
    {
        id: SubSystemChartEnum.ACType,
        title: '人员类型统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/access-control-rts-type',
        chartType: ChartType.PIE,
        chartData: null,
        service: AccessControlIndexService.getRtsTypeCount,
        serviceParams: new AccessControlRtsTypeQueryModel(),
        systemId: SubSystemType.ACCESS_CONTROL
    },
    {
        id: SubSystemChartEnum.ACAlarmStatus,
        title: '告警状态统计',
        loading: true,
        redirectPath: '/dashboard/access-control-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: AccessControlIndexService.getAlarmStatusCount,
        serviceParams: '',
        systemId: SubSystemType.ACCESS_CONTROL
    },
    {
        id: SubSystemChartEnum.ACAlarmTrend,
        title: '近7天告警趋势',
        loading: true,
        redirectPath: '/dashboard/parking-chart/access-control-alarm-trend',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: AccessControlIndexService.getLast7DayAlarmCount,
        serviceParams: new AccessControlAlarmTrendQueryModel(),
        systemId: SubSystemType.ACCESS_CONTROL
    },
    {
        title: '容量统计',
        loading: true,
        redirectPath: '/dashboard/parking-chart/computer-room-capacity',
        chartType: ChartType.GAUGE,
        chartData: null,
        percentId: 'data[0].data',
        percentDesc: '使用率',
        legendTitle: '全部',
        legendItems: [
            {
                title: '额定U位',
                dataIndex: 'data[0].rateU'
            },
            {
                title: '未用U位',
                dataIndex: 'data[0].remainU'
            }
        ],
        service: ComputerRoomCapacityService,
        serviceParams: new ComputerRoomCapacityQueryModel(),
        showChartTable: true,
        rowKey: 'locationId',
        chartTableDataSource: 'data[0].children',
        chartTableColumns: [
            {
                dataIndex: 'locationName',
                title: '区域'
            },
            {
                dataIndex: 'rateU',
                title: '额定U位'
            },
            {
                dataIndex: 'remainU',
                title: '未用U位'
            },
            {
                dataIndex: 'useURate',
                title: '使用率',
                scopedSlots: { customRender: 'rate' }
            }
        ],
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        title: '温湿度状态监控',
        loading: true,
        redirectPath: '/dashboard/computer-room-facility-list',
        service: ParkingFacilityService.query,
        serviceParams: new ParkingFacilityQueryModel(SubSystemType.COMPUTER_ROOM, ['TemperatureHumidityMeter']),
        showChartTable: true,
        onlyTable: true,
        chartTableDataSource: 'items',
        chartTableColumns: [
            {
                dataIndex: 'name',
                title: '设备名称',
                ellipsis: true
            },
            {
                title: '温度',
                customRender: (text, record, index) => {
                    return `${_.get(record, 'attrs.Temperature.value')}${_.get(record, 'attrs.Temperature.unit') || ''}`;
                }
            },
            {
                title: '湿度',
                customRender: (text, record, index) => {
                    return `${_.get(record, 'attrs.Humidity.value')}${_.get(record, 'attrs.Humidity.unit') || ''}`;
                }
            }
        ],
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        title: '电池组状态监控',
        loading: true,
        redirectPath: '/dashboard/computer-room-facility-list',
        service: ParkingFacilityService.query,
        serviceParams: new ParkingFacilityQueryModel(SubSystemType.COMPUTER_ROOM, ['BatteryPack']),
        showChartTable: true,
        onlyTable: true,
        tableHeight: 338,
        chartTableDataSource: 'items',
        chartTableColumns: [
            {
                dataIndex: 'name',
                title: '设备名称',
                ellipsis: true
            },
            {
                title: '环境温度',
                customRender: (text, record, index) => {
                    return `${_.get(record, 'attrs.AmbientTemperature.value') || ''}${_.get(record, 'attrs.AmbientTemperature.unit') || ''}`;
                }
            },
            {
                title: '组电压',
                customRender: (text, record, index) => {
                    return `${_.get(record, 'attrs.GroupVoltage.value') || ''}${_.get(record, 'attrs.GroupVoltage.unit') || ''}`;
                }
            },
            {
                title: '组电流',
                customRender: (text, record, index) => {
                    return `${_.get(record, 'attrs.GroupCurrent.value') || ''}${_.get(record, 'attrs.GroupCurrent.unit') || ''}`;
                }
            }
        ],
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        title: '电量仪状态监控',
        loading: true,
        redirectPath: '/dashboard/computer-room-facility-list',
        service: ParkingFacilityService.query,
        serviceParams: new ParkingFacilityQueryModel(SubSystemType.COMPUTER_ROOM, ['ElectricityMeter']),
        showChartTable: true,
        onlyTable: true,
        tableHeight: 300,
        chartTableDataSource: 'items',
        chartTableColumns: [
            {
                dataIndex: 'name',
                title: '设备名称',
                ellipsis: true
            },
            {
                title: '电压',
                children: [
                    {
                        title: 'A',
                        customRender: (text, record, index) => {
                            return `${_.get(record, 'attrs.PhaseAVoltage.value') || ''}${_.get(record, 'attrs.PhaseAVoltage.unit') || ''}`;
                        }
                    },
                    {
                        title: 'B',
                        customRender: (text, record, index) => {
                            return `${_.get(record, 'attrs.PhaseBVoltage.value') || ''}${_.get(record, 'attrs.PhaseBVoltage.unit') || ''}`;
                        }
                    },
                    {
                        title: 'C',
                        customRender: (text, record, index) => {
                            return `${_.get(record, 'attrs.PhaseCVoltage.value') || ''}${_.get(record, 'attrs.PhaseCVoltage.unit') || ''}`;
                        }
                    }
                ]
            },
            {
                title: '电流',
                children: [
                    {
                        title: 'A',
                        customRender: (text, record, index) => {
                            return `${_.get(record, 'attrs.PhaseACurrent.value') || ''}${_.get(record, 'attrs.PhaseACurrent.unit') || ''}`;
                        }
                    },
                    {
                        title: 'B',
                        customRender: (text, record, index) => {
                            return `${_.get(record, 'attrs.PhaseBCurrent.value') || ''}${_.get(record, 'attrs.PhaseBCurrent.unit') || ''}`;
                        }
                    },
                    {
                        title: 'C',
                        customRender: (text, record, index) => {
                            return `${_.get(record, 'attrs.PhaseCCurrent.value') || ''}${_.get(record, 'attrs.PhaseCCurrent.unit') || ''}`;
                        }
                    }
                ]
            },
            {
                title: '系统有功功率',
                customRender: (text, record, index) => {
                    return `${_.get(record, 'attrs.SystemActivePower.value') || ''}${_.get(record, 'attrs.SystemActivePower.unit') || ''}`;
                }
            }
        ],
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        title: '今天告警状态统计',
        loading: true,
        redirectPath: '/dashboard/computer-room-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: ComputerRoomIndexService.getAlarmStatusCount,
        serviceParams: '',
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        title: '今天告警级别统计',
        loading: true,
        redirectPath: '/dashboard/computer-room-event',
        chartType: ChartType.PIE,
        chartData: null,
        service: ComputerRoomIndexService.getAlarmGradeCount,
        serviceParams: '',
        systemId: SubSystemType.COMPUTER_ROOM
    },
    {
        title: '近7天告警趋势',
        loading: true,
        redirectPath: '/dashboard/computer-room-event',
        chartType: ChartType.LINE,
        chartData: null,
        hideLegend: true,
        service: ComputerRoomIndexService.getLast7DayAlarmCount,
        serviceParams: '',
        systemId: SubSystemType.COMPUTER_ROOM
    }
];
