
























































































































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartRingComponent, ReportChartLineComponent, ReportChartHistogramComponent } from '@common-src/mixins/report-chart-base';
import SubSystemComponent from '@common-src/mixins/sub-system-component';
import { percentFormat } from '@common-src/filter';
import { ChartType } from '@common-src/model/enum';
import { HeaderCardList, ChartConfigList } from '@/pages/sub-system/model/subsystem-index';

@Component
export default class SubsystemIndexComponent extends Mixins(ReportChartRingComponent, ReportChartLineComponent, ReportChartHistogramComponent, SubSystemComponent) {
    ChartType = ChartType;
    percentFormat = percentFormat;

    HeaderCardList = HeaderCardList;
    ChartConfigList = ChartConfigList;

    // created() {
    //     this.initPage();
    // }

    initPage() {
        this.HeaderCardList = _.filter(HeaderCardList, item => item.systemId === this.SubSystemName);
        this.ChartConfigList = _.filter(ChartConfigList, item => item.systemId === this.SubSystemName);

        _.forEach(this.HeaderCardList, cardItem => {
            cardItem.service().then(res => {
                cardItem.count = res[cardItem.dataIndex];
            }).finally(() => {
                cardItem.loading = false;
            });
        });

        _.forEach(this.ChartConfigList, chartItem => {
            chartItem.service(chartItem.serviceParams).then(res => {
                chartItem.chartData = res;
            }).finally(() => {
                chartItem.loading = false;
            });
        });
    }

    async groupChange(ev: any, chartItem) {
        chartItem.serviceParams.type = _.get(ev, 'target.value');
        chartItem.service(chartItem.serviceParams).then(res => {
            chartItem.chartData = res;
        });
    }
}
